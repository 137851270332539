:root {
    font-size: 62.5%;
}

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

#outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color:whitesmoke;
    background-image: url('.././assets/images/main.jpg');
    background-size: cover;
    background-repeat:no-repeat;
}

#inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.5%;
    width: 90%;
    height: 90%;
}

#logout-container { /* Style Log Out page area  */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 10%;
}

#logout-button { /* Style Log Out button  */
   cursor: pointer;
   width:10%;
   height:56.5%;
   background-color:#36454f;
   box-shadow: 0 3px 1px 1px #000000;
   border-radius: 10px;
   color:whitesmoke;
   font-size: calc(1.0vw + 3px);
 }

#search {  /* Style search area on page */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 25%;
    width: 100%;
    height: 18%;
    font-family: Arial, Helvetica, sans-serif;
}

#input-style { /* Style input box */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32%;
    height: 40%;
    border-radius: 4px;
    box-shadow: 0 2px 1px 1px #000000;
    padding: 5px;
    color: black;
    text-align: center;
    font-size: calc(1.0vw + 2px);
}

#button-style { /* Style search button */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width:15%;
    height: 40%;
    padding: 2px;
    color: whitesmoke;
    font-size: calc(1.0vw + 3px);
    box-shadow: 0 3px 1px 1px #000000;
    background-color:#36454f;
    border-radius: 27px;
}

 #selection_button_container { /* Style Buttons for selecting breaks / roster and Oracle stand by or Mozaic */
     background-color:whitesmoke;
     width:100vw;
     height:70px;
    }

 #selection_button { /* Style Buttons for selecting breaks / roster and Oracle stand by or Mozaic */
     position: absolute;
     z-index: 1;
     top:38.5%;
     left:54.5%;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color:rgb(152 151 151 / 15%);
     box-shadow: 0 -4px 4px 4px #000000;
     width:210px;
     height:55px;
     borderRadius:10px;
     font-size:15px;
     color:black;
     cursor:pointer;
     border-radius: 10px;
    }

  .styleCSS  { /* Style Buttons for selecting sites */
          text-decoration:gold;
          cursor:pointer;
         /* border:0.5px black solid;*/
          background-color:inherit;
          padding:8px;
          color:white;
          font-size: 20px;
          min-height: 50px;
          border-radius:2px;
          margin-top:0.5px;
          margin-bottom:0.5px;
          box-shadow: 0 2px 2px 2px lab(23% -36 1);
        }

  .clicked  { /* Style Buttons for selecting sites */
          cursor:pointer;
          background-color:lightblue;
        }     

 .styleCSS:hover { /* Style Buttons for selecting sites */
    background-color:lightseagreen;
  }

  .styleCSS:focus { /* Style Buttons for selecting sites */
    background-color:lightblue;
  }

 #selection_button:hover {
   background-color:lightseagreen;
  }

 #logout-button:hover {
   background-color: red;
  }

 #button-style:hover {
    background-color:lightseagreen;
  }

#main-style {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap:3%;
    width: 100%;
    height: 65%;
    color: whitesmoke;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
  }

#flex-item-right {
    cursor: pointer;
    overflow-y: auto;
    overflow-x: auto;
    width: 23%;   
    height: 100%;
    padding: 8px;
    box-shadow: 0 4px 2px 2px #000000;
    background-color: rgba(1, 12, 63, 0.7);
  }

#flex-item-left {
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 0 4px 2px 2px #000000;
    width: 71%;  
    height: 100%; 
    padding: 8px;
    background-color: rgba(1, 12, 63, 0.7);
    opacity:1.2;
  }

#pdf-container {
   display: flex;
   justify-content: center;
   user-select: none;
  }
  
  @media print {
    #outer-container {
      display: none;
    }
  }
  /* min-width will be , 263,375,449  */
    @media screen and (max-height: 650px) {
    #outer-container {
        background-color: green;
        background-image: none;
      } 
   }

       @media screen and (max-height: 550px) {
    #outer-container {
        background-color: orange;
        background-image: none;
      } 
   }

   @media screen and (max-height: 500px) {
    #outer-container {
        background-color: red;
        background-image: none;
      } 
   }

      @media screen and (max-height: 400px) {
    #outer-container {
        background-color: blue;
        background-image: none;
      } 
   }

    @media screen and (max-height: 350px) {
    #outer-container {
        background-color: silver;
        background-image: none;
      } 
   }

  

