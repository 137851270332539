:root {
    font-size: 100%;
}

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

#container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #008080;
    background-image: url('.././assets/images/main.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

#container-otp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #36454f;
    background-image: url('.././assets/images/ai1.gif');
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
   }

#auth-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 30px 150px 15px whitesmoke;
    border: 2px solid lab(21% -160 83);
    border-radius: 15px;
    width: 460px;
    height: 32%;
   /* background-color:rgb(152 151 151 / 25%);*/
   /* background-color:rgb(21, 49, 78);  */
   /* background-color:rgb(152 151 151 / 15%); */
   /* background-color: #2F4F4F;*/
   background-color: rgb(152 151 151 / 15%);
}

#auth-container:hover {
    width: 470px;
    height: 35%;
}

#button-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    margin: 1px 1px 10px 1px;
    height: 19.5%;
    width:26%;
    background-color:black;
    border-radius: 6px;
    box-shadow: 0 6px 2px 2px #000000;
    color:white;
    font-size: 20px;
    font-weight: 600;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
  }

#button-otp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    margin: 1px 1px 10px 1px;
    height: 23.5%;
    width:26%;
    background-color:azure;
    border-radius: 5px;
    color:black;
    font-size: 20px;
    font-weight: 600;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
  }

#button-otp:hover {
    background-color: lightseagreen;
}

#button-login:hover {
     background-color:lightseagreen;
}

.decorateInput {
    backgroundColor:azure;
 }

.decorateInput:hover {
    background-color: #36454f; 
    color:white;
}

@media screen and (max-width: 650px) {
    #auth-container {
        width: 250px;
        height: 150px;
    }

    #button-otp {
      font-size: 10px;
    }

    #auth-container:hover {
        width: 280px;
        height: 180px;
    }
    button {
        color:black;
        font-size: 1.5rem;
        font-weight: 560;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
    }

    input {
        color:black;
        font-size: 1.3rem;
        font-weight: 560;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
    }
  }

