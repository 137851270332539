.location-box{
    margin-top: 20px;
    text-align:center;
  }
  
  .location-box .location{
    color:whitesmoke;
    font-size:24px;
    font-weight: 500;
    text-align: center;
   /* text-shadow: 3px 3px rgba(50,50,70,0.5);*/
  }
  
  .location-box .date{
    position:relative;
    display:inline-block;
    margin: 25px auto;
    background-color: rgba(255,255,255,0.2);
    border-radius: 16px;
    color:whitesmoke;
    font-size:40px;
    font-weight: 700;
    text-align: center;
    padding :5px 5px;
   /* text-shadow: 3px 6px rgba(50,50,70,0.5);*/
    box-shadow: 3px 6px rgba(0,0,0,0.2);
  }
  
  .weather-box{
    text-align: center;
  }
  
  .weather-box .temp{
  
    position:relative;
    display: inline-block;
    margin: 30px auto;
    background-color: rgba(255,255,255,0.2);
    border-radius: 16px;
    padding :10px 20px;
    color:whitesmoke;
    font-size:40px;
    font-weight: 700;
  
    /*text-shadow: 3px 6px rgba(50,50,70,0.5);*/
  
    text-align: center;
  
    box-shadow: 3px 6px rgba(0,0,0,0.2);
  }
  
  .weather-box .weather{
    color:whitesmoke;
    font-size:48px;
    font-weight: 600;
    /*text-shadow: 3px 3px rgba(50,50,70,0.5);*/
  }